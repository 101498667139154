.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.linkContainer {
  margin-top: 8px;
  margin-bottom: 3px;
  width: 45ch;
}
.linkContainer .link {
  text-decoration: none;
  color: olive;
  font-weight: 500;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-alert {
  position: fixed;
  right: 1rem;
  top: 1rem;
}

.App-alert > * + * {
  margin-top: 1rem;
}

//fc-daygrid-day fc-day fc-day-tue fc-day-past
.fc-day.fc-day-past {
  background-color: rgba(203, 192, 192, 0.67);
  color: gray;
}

.fc-day.fc-day-sun.fc-day-future {
  background-color: rgb(154, 154, 154);
}

.fc .fc-daygrid-dot-event::before {
  color: rgb(0, 0, 0);
}

.fc .fc-daygrid-dot-event:hover {
  color: rgb(0, 0, 0);
}

.fc-toolbar-title {
  &::first-letter {
    text-transform: capitalize;
  }
}
